<template>
	<div class="container">
			<div class="box">
				<div class="title">{{ data.locationnum }}</div>
				<div class="con">
					<div class="line">
						<div class="left">宗地编号</div>
						<div class="right">{{ data.locationnum }}</div>
					</div>
					<div class="line">
						<div class="left">成交日期</div>
						<div class="right">{{ data.tradedate }}</div>
					</div>
					<div class="line">
						<div class="left">竞得单位</div>
						<div class="right">{{ data.company }}</div>
					</div>
					<div class="line">
						<div class="left">地块位置</div>
						<div class="right">{{ data.location }}</div>
					</div>
					<div class="line">
						<div class="left">用途分类</div>
						<div class="right">{{ data.usecatename }}</div>
					</div>
					<div class="line">
						<div class="left">出让年限</div>
						<div class="right">{{ data.useyears }}</div>
					</div>
					<div class="line">
						<div class="left">地块面积(m²)</div>
						<div class="right">{{ data.acreage }}</div>
					</div>
					<div class="line">
						<div class="left">容积率</div>
						<div class="right">{{ data.volume }}</div>
					</div>
					<div class="line">
						<div class="left">建筑面积(m²)</div>
						<div class="right">{{ data.buildacreage }}</div>
					</div>
					<div class="line">
						<div class="left">成交总价(万元)</div>
						<div class="right">{{ data.tradeprice }}</div>
					</div>
					<div class="line">
						<div class="left">楼面价(元/m²)</div>
						<div class="right">{{ data.floorprice }}</div>
					</div>
					<div class="line">
						<div class="left">溢价率</div>
						<div class="right">{{ data.overrage }}</div>
					</div>
				</div>
			</div>
	</div>
</template>

<script>

import { detail } from "@/api/aowei";

export default {
	data(){
		return {
			data:null
		}
	},
	
watch: {
  $route: {
    handler: function(val, oldVal){
      const id = val.params.id
			detail({id:id}).then(res=>{
				this.data = res.data
			})
    },
    // 深度观察监听
    deep: true
  }
},
	mounted(){
		
		this.init()
	},
	methods:{
		init(){
			if(this.$route.params.id!=null){
				detail({id:this.$route.params.id}).then(res=>{
				this.data = res.data
				console.log('data',res.data)
			})
			}
			
		}
	}



};
</script>

<style lang="scss" scoped>
	.container{
		width:100%;
		height:100%;
		display: flex;
		justify-content: center;

		.box{
			margin-top:20px;
			width: 350px;
			height:400px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.title{
				line-height: 20px;
				font-weight:bold;
				font-size:16px;
			}
			.con{
				width:100%;
				display: flex;
				flex-direction: column;
				border:solid 1px #0694ec;
				border-radius: 5px;
				overflow: hidden;
				.line{
					display: flex;
					flex-direction: row;
				
					.left{
						width:100px;
						background: #0694ec;
						color:#fff;
						border-bottom:solid 1px #fff;
						
					}
					.right{
						width:250px;
						color:#000;
						border-bottom:solid 1px #0694ec;
						
					}
					
				}
				.line:last-child{
					.left{
						width:100px;
						background: #0694ec;
						color:#fff;
						border-bottom:none;
						
					}
					.right{
						width:250px;
						color:#000;
						border-bottom:none;
						
					}
				}
			}
		}
	}
</style>